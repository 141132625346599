<template>
    <PageWrapper>
        <div v-if="isSuperUser">
            <SwitchOrganizationGrid
                label="Select Organization"
                @select="$emit('select', $event)"
                @error="$log('Failed to select an organization...', $event)"
            />
        </div>
    </PageWrapper>
</template>

<script>
    // <!-- API -->
    import { defineComponent, computed } from 'vue';
    import { useStore } from 'vuex';

    // <!-- COMPONENTS -->
    import PageWrapper from '@/components/PageWrapper.vue';

    // <!-- FIXTURES -->
    import SwitchOrganizationGrid from '@/features/switch-organization/components/SwitchOrganizationGrid.vue';

    // <!-- TYPES -->
    /** @template [S=any] @typedef {import('vuex').Store<S>} Store<S> */
    import { ECNBState } from '@/store/types/ECNBStore';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'SelectOrganization',
        components: {
            PageWrapper,
            SwitchOrganizationGrid,
        },
        emits: ['select', 'error'],
        setup(props, context) {
            // ==== COMPOSABLES ====
            const store = useStore();

            /** @type {V.ComputedRef<Store<ECNBState>['state']['users']['me']>} */
            const user = computed(() => store.state.users.me);

            const isSuperUser = computed(
                () =>
                    //TODO: Replace with ipi admin role
                    //user.value.role === 'ipiadmin'
                    true
            );
            return {
                isSuperUser,
            };
        },
    });
</script>
